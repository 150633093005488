import React, { useState, useEffect, useRef } from 'react';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

const solutions = {
    digitalMarketing: [
        { name: 'Search Engine Optimization (SEO)', href: '/digitalMarketing#dmServices1' },
        { name: 'Content Marketing', href: '/digitalMarketing#dmCards' },
        { name: 'Social Media Marketing', href: '/digitalMarketing#localSEO' },
        { name: 'Affiliate Marketing', href: '/digitalMarketing#affiliateMarketing' },
        { name: 'Mobile Marketing', href: '/digitalMarketing#mobileMarketing' },
    ],
    itServices: [
        { name: 'Design & Development', href: '/itServices#services1' },
        { name: 'Cloud Computing Services', href: '/itServices#cloudItService' },
        { name: 'Software Development', href: '/itServices#softwareDevelopment' },
        { name: 'Web Hosting', href: '/itServices#itServicesCards' },
        { name: 'Domain Registration', href: '/itServices#itServicesCards' },
    ],
    softwareProducts: [
        { name: 'Institute Management System (IBS)', href: '/softwareProducts#ERP' },
        { name: 'Real Estate Management System (REMS)', href: '/softwareProducts#EBS' },
        { name: 'Hospital Management System (HMS)', href: '/softwareProducts#CRM' },
        { name: 'Environmental, Social and Governance (ESG)', href: '/softwareProducts#IMS' },
        { name: 'Integrated Business Suite (IBS)', href: '/softwareProducts#IMS' },
        { name: 'Enterprise Resource Planning (ERP)', href: '/softwareProducts#IMS' },
    ],
};

const Navbar = () => {
    const [isServicesPopoverOpen, setIsServicesPopoverOpen] = useState(false);
    const [isProductsPopoverOpen, setIsProductsPopoverOpen] = useState(false);
    const servicesRef = useRef(null);
    const productsRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Close Services dropdown if clicked outside
            if (servicesRef.current && !servicesRef.current.contains(event.target)) {
                setIsServicesPopoverOpen(false);
            }

            // Close Products dropdown if clicked outside
            if (productsRef.current && !productsRef.current.contains(event.target)) {
                setIsProductsPopoverOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [servicesRef, productsRef, setIsServicesPopoverOpen, setIsProductsPopoverOpen]);

    return (
        <nav
            className="shadow-md sticky top-0 left-0 z-50 w-full"
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                backdropFilter: 'blur(5px)', // Adds a subtle blur effect
            }}
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center">
                    {/* Logo */}
                    <div className="flex-shrink-0">
                        <img
                            className="h-10"
                            src={require('../assets/images/Logo.png')}
                            alt="Logo"
                        />
                    </div>

                    {/* Navigation Links */}
                    <div className="flex-grow flex justify-end">
                        <div className="flex max-w-6xl justify-between">
                            <div className="group flex-grow flex-shrink-0 relative h-12 flex items-center justify-center mx-2">
                                <a
                                    href="/"
                                    className="text-[#1e266d] font-heading font-semibold relative group-hover:text-[#1E699F]"
                                >
                                    Home
                                </a>

                            </div>
                            <div className="group flex-grow flex-shrink-0 relative h-12 flex items-center justify-center mx-2">
                                <a
                                    href="/about"
                                    className="text-[#1e266d] font-heading font-semibold relative group-hover:text-[#1E699F]"
                                >
                                    About
                                </a>

                            </div>

                            {/* Services Dropdown */}
                            <div
                                className="relative group flex-grow flex-shrink-0 h-12 flex items-center justify-center mx-2"
                                ref={servicesRef}
                                onMouseEnter={() => setIsServicesPopoverOpen(true)}
                                onMouseLeave={() => setIsServicesPopoverOpen(false)}
                            >
                                <button className="inline-flex items-center gap-x-1 text-[#1e266d] hover:text-[#1E699F] font-heading font-semibold relative">
                                    <span>Services</span>

                                </button>
                                <span className="absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>

                                {isServicesPopoverOpen && (
                                    <div className="absolute left-1/2 top-full z-50 mt-1 w-max max-w-screen-lg -translate-x-1/2">
                                        <div className="flex w-max max-w-screen-lg overflow-hidden text-sm leading-6 shadow-lg ring-1 ring-gray-900/5" style={{ backgroundColor: 'rgba(12, 12, 12, 0.95)', boxShadow: '0px 0px 10px rgba(0,0,0,0.3)' }}>
                                            {/* DIGITAL MARKETING */}
                                            <div className="p-4 flex-1">
                                                <a href='/digitalMarketing' className="font-semibold text-lg text-white mb-3 hover:text-[#1E699F]">DIGITAL MARKETING</a>
                                                {solutions.digitalMarketing.map((item) => (
                                                    <div key={item.name} className="group h-auto relative flex gap-x-6 rounded-lg p-2">
                                                        <a href={item.href} className="text-white text-md hover:text-[#1E699F] whitespace-nowrap">
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* IT SERVICES */}
                                            <div className="p-4 flex-1">
                                                <a href='/itServices' className="font-semibold text-lg text-white mb-3 hover:text-[#1E699F]">IT SERVICES</a>
                                                {solutions.itServices.map((item) => (
                                                    <div key={item.name} className="group h-auto relative flex gap-x-6 rounded-lg p-2">
                                                        <a href={item.href} className="text-white text-md hover:text-[#1E699F] whitespace-nowrap">
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Products Dropdown */}
                            <div
                                className="relative group flex-grow flex-shrink-0 h-12 flex items-center justify-center mx-2"
                                ref={productsRef}
                                onMouseEnter={() => setIsProductsPopoverOpen(true)}
                                onMouseLeave={() => setIsProductsPopoverOpen(false)}
                            >
                                <button className="inline-flex items-center gap-x-1 text-[#1e266d] font-heading font-semibold relative hover:text-[#1E699F]">
                                    <a href="/softwareProducts">Products</a>
                                </button>
                                <span className="absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>

                                {isProductsPopoverOpen && (
                                    <div className="absolute left-1/2 top-full z-50 mt-1 w-max max-w-screen-lg -translate-x-1/2">
                                        <div className="flex w-max max-w-screen-lg overflow-hidden text-sm leading-6 shadow-lg ring-1 ring-gray-900/5" style={{ backgroundColor: 'rgba(12, 12, 12, 0.95)', boxShadow: '0px 0px 10px rgba(0,0,0,0.3)' }}>
                                            {/* SOFTWARE PRODUCTS */}
                                            <div className="p-4 flex-1">
                                                <p className="font-semibold text-lg text-white mb-3">SOFTWARE PRODUCTS</p>
                                                {solutions.softwareProducts.map((item) => (
                                                    <div key={item.name} className="group h-auto relative flex gap-x-6 rounded-lg p-2">
                                                        <a href={item.href} className="text-white text-md hover:text-[#1E699F] whitespace-nowrap">
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="group flex-grow flex-shrink-0 relative h-12 flex items-center justify-center mx-2">
                                <a
                                    href="/contact"
                                    className="text-[#1e266d] font-heading font-semibold relative group-hover:text-[#1E699F]"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Social Media Icons */}
                    <div className="flex space-x-4">
                        <a href="https://www.facebook.com/Scitforte" target="_blank" rel="noopener noreferrer" className='pl-2 text-[#1e266d]'>
                            <FaFacebook size={20} className="hover:text-[#1E699F] transition duration-300" />
                        </a>
                        <a href="https://www.linkedin.com/company/scitforte/mycompany/" target="_blank" rel="noopener noreferrer" className=' text-[#1e266d]'>
                            <FaLinkedin size={20} className="hover:text-[#1E699F] transition duration-300" />
                        </a>
                        <a href="https://www.instagram.com/scitforte?igsh=Zmp3aW5yaDQxaG05" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={20} className="text-pink-600 hover:text-pink-800 transition duration-300" />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
