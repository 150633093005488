import React, { useState, useEffect } from "react";
import { Fade } from 'react-awesome-reveal';
import './style.css';
import { useSpring, animated } from '@react-spring/web';

import backgroundVideo from '../../assets/images/backgroundProducts.mp4'

import { GrSystem } from "react-icons/gr";
import { FaHospitalAlt } from "react-icons/fa";
import { BiSolidBuildingHouse } from "react-icons/bi";
import { FaBuildingUser } from "react-icons/fa6";
import { RiWindowFill, RiCheckboxCircleFill, RiGovernmentFill } from "react-icons/ri";

import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Products = () => {

    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const sections = document.querySelectorAll('section');

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.5 }
        );

        sections.forEach((section) => {
            observer.observe(section);
        });

        return () => {
            sections.forEach((section) => {
                observer.unobserve(section);
            });
        };
    }, []);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <div id="products">
            <section id="heading" className="flex relative">
                {/* Background Video and Overlay */}
                <video
                    className="absolute inset-0 w-full h-full object-cover"
                    src={backgroundVideo}
                    autoPlay
                    loop
                    muted
                    style={{ opacity: '0.25' }}
                />

                {/* Main Content */}
                <div className="relative max-w-7xl mx-auto flex flex-col items-center justify-center h-screen z-10">
                    <h1 className="text-5xl font-bold text-center text-white">
                        <span className="gradient-headings">
                            Scitforte Software Products
                        </span>
                    </h1>
                    <p className="text-2xl text-white mt-6 mx-auto max-w-4xl text-center">
                        We offer innovative solutions with intuitive interfaces and robust backend systems to streamline operations and boost efficiency, empowering businesses to thrive in the digital age.
                    </p>
                </div>
            </section>

            <section id="productsNav" className="sticky top-[80px] z-10">
                <nav className="shadow-md w-full mt-6" style={{ backgroundColor: 'rgba(12, 12, 12, 0.85)', boxShadow: '0px 0px 10px rgba(0,0,0,0.3)' }}>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between items-center">

                            {/* Navigation Links */}
                            <div className="flex-grow flex justify-center">
                                <div className="flex justify-between">
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20 flex items-center justify-between w-[125px]">
                                        <GrSystem className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#institueBS"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'institueBS' ? 'active' : ''}`}
                                        >
                                            IBS
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'institueBS' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20 flex items-center justify-between w-[125px]">
                                        <BiSolidBuildingHouse className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#REMS"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'REMS' ? 'active' : ''}`}
                                        >
                                            REMS
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'REMS' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20  flex items-center justify-center w-[125px]">
                                        <FaHospitalAlt className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#HMS"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'HMS' ? 'active' : ''}`}
                                        >
                                            HMS
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'HMS' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20 flex items-center justify-center w-[125px]">
                                        <RiGovernmentFill className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#ESG"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'ESG' ? 'active' : ''}`}
                                        >
                                            ESG
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'ESG' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20 flex items-center justify-center w-[125px]">
                                        <FaBuildingUser className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#ERP"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'ERP' ? 'active' : ''}`}
                                        >
                                            ERP
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'ERP' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                    <div className="group px-6 flex-grow flex-shrink-0 relative h-20 flex items-center justify-center w-[125px]">
                                        <RiWindowFill className="text-white h-6 w-6 mx-auto" aria-hidden="true" />
                                        <a
                                            href="#IBS"
                                            className={`text-heading text-white font-heading font-semibold text-lg relative mx-auto group-hover:text-white ${activeSection === 'IBS' ? 'active' : ''}`}
                                        >
                                            IBS
                                        </a>
                                        <span className={`absolute bottom-0 left-0 w-full h-1 bg-[#1E699F] transform ${activeSection === 'IBS' ? 'scale-x-100' : 'scale-x-0'} transition-transform duration-300`}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>

            <section
                id="institueBS"
                className="ct-section section_layout"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    className="ct-section-inner-wrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block p-6"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img
                                id="image-1087-20"
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/CRM.jpg')}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            id="div_block-1079-20"
                            className="ct-div-block section_div"
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                id="headline-1080-20"
                                className="text-white text-left gradient-headings text-4xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Institute Management Systems
                            </h2>
                            <p
                                id="text_block-1086-20"
                                className="ct-text-block text-white content_column_description"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Enhance institutional efficiency and academic excellence with a comprehensive system that organizes, and student management, creating a more cohesive and responsive educational experience.
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="REMS"
                className="ct-section section_layout"
            >
                <div
                    className="ct-section-inner-wrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        {/* Main Content Section */}
                        <div
                            id="div_block-1079-20"
                            className="ct-div-block section_div px-12"
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                id="headline-1080-20"
                                className="text-white text-left gradient-headings text-4xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Real Estate Management System
                            </h2>
                            <p
                                id="text_block-1086-20"
                                className="ct-text-block text-white content_column_description"
                                style={{
                                    marginBottom: '1.5rem',
                                    textAlign: 'left',
                                }}
                            >
                                Streamline real estate operations and boost client engagement with a comprehensive system that organizes property <br /> management tasks creating a more integrated and effective real estate management experience.
                            </p>
                        </div>
                    </Fade>

                    <Fade direction="right">
                        {/* Image Section */}
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block p-6"
                        >
                            <img
                                id="image-1087-20"
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/EBS.jpg')}
                                style={{
                                    width: '75%',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="HMS"
                className="ct-section section_layout"
            >
                <div
                    className="aje ct-section-inner-wrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        {/* Image Section */}
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block p-6 3/4"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/IMS.jpg')}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        {/* Main Content Section */}
                        <div
                            className="ct-div-block section_div"
                            style={{
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="text-white text-left gradient-headings text-3xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Hospital Management System
                            </h2>
                            <p
                                className="ct-text-block text-white content_column_description"
                                style={{
                                    marginBottom: '1.5rem',
                                    textAlign: 'left',
                                }}
                            >
                                Optimize healthcare operations and improve patient care with a robust <br /> Hospital Management System that automates administrative tasks, streamlines patient management, ensuring effective hospital environment.
                            </p>
                            <ul className="text-white text-left">
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Centralized patient record management</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Automated appointment scheduling</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Efficient billing and invoicing</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Enhanced communication between departments</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Inventory and supply chain management</li>
                            </ul>
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="ESG"
                className="ct-section section_layout"
            >
                <div
                    className="ct-section-inner-wrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        {/* Main Content Section */}
                        <div
                            id="div_block-1079-20"
                            className="ct-div-block section_div px-12"
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                id="headline-1080-20"
                                className="text-white text-left gradient-headings text-4xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Environment, Social and Governance
                            </h2>
                            <p
                                id="text_block-1086-20"
                                className="ct-text-block text-white content_column_description"
                                style={{
                                    marginBottom: '1.5rem',
                                    textAlign: 'left',
                                }}
                            >
                                Integrate sustainability into your business with a comprehensive
                                <br />Environment, Social, and Governance (ESG) strategy that drives
                                <br />ethical practices, enhances corporate responsibility, and fosters long-term stakeholder trust.
                            </p>
                        </div>
                    </Fade>

                    <Fade direction="right">
                        {/* Image Section */}
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block p-6"
                        >
                            <img
                                id="image-1087-20"
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/EBS.jpg')}
                                style={{
                                    width: '75%',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="ERP"
                className="ct-section section_layout aje"
                style={{
                    padding: '50px 0',
                }}
            >
                <div
                    className="ct-section-inner-wrap"
                    style={{
                        display: 'flex', // Set the parent element to flex
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block px-6"
                        >
                            <img
                                id="image-1087-20"
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/ERP.jpg')}
                                alt="ERP"
                                style={{
                                    width: '100%',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        </div>
                    </Fade>
                    <Fade direction="right">
                        <div
                            id="div_block-1079-20"
                            className="ct-div-block section_div"
                            style={{
                                flex: '3', // 30% of the width
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                id="headline-1080-20"
                                className="text-white text-left gradient-headings font-semibold text-4xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    marginBottom: '12px',
                                    lineHeight: '1.2',
                                    transition: 'transform 0.5s ease',
                                }}
                            >
                                Enterprise Resource Planning
                            </h2>
                            <p
                                id="text_block-1086-20"
                                className="ct-text-block content_column_description"
                                style={{
                                    color: '#cdcdcd',
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Streamline business processes, integrate departments, and enhance overall
                                efficiency through a comprehensive software solution that manages resources,
                                finances, and operations seamlessly.
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="IBS"
                className="ct-section section_layout"
            >
                <div
                    className="ct-section-inner-wrap"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        {/* Main Content Section */}
                        <div
                            id="div_block-1079-20"
                            className="ct-div-block section_div px-12"
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                id="headline-1080-20"
                                className="text-white text-left gradient-headings text-4xl ct-headline hyperion_tabs-1_title"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Integrated Business Suite
                            </h2>
                            <p
                                id="text_block-1086-20"
                                className="ct-text-block text-white content_column_description"
                                style={{
                                    marginBottom: '1.5rem',
                                    textAlign: 'left',
                                }}
                            >
                                Maximize operational efficiency with an Integrated Business Suite <br /> that seamlessly connects your business processes, enhances data flow, and drives informed decision-making across all departments.
                            </p>
                            <ul className="text-white text-left">
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Unified data management</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Streamlined workflow automation</li>
                                <li className="flex items-center mb-2"><RiCheckboxCircleFill className="mx-2" />Enhanced real-time reporting</li>
                            </ul>
                        </div>
                    </Fade>

                    <Fade direction="right">
                        {/* Image Section */}
                        <div
                            id="div_block-1520-76"
                            className="relative ct-div-block p-6"
                        >
                            <img
                                id="image-1087-20"
                                className="ct-image transform transition-transform duration-500 ease-in-out hover:scale-110 hover:rotate-2 hover:shadow-lg rounded-lg"
                                src={require('../../assets/images/products/EBS.jpg')}
                                style={{
                                    width: '75%',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        </div>
                    </Fade>
                </div>
            </section>

            <section ref={ref}>
                <div className="aje ase py-16">
                    <div className="gx uh arq dit">
                        <div className="gx uc dcy flex flex-col items-center">
                            <dl className="la mb yn zq adn avr cfw def rounded-2xl mt-6 mb-12">
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Team members</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={20} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Years of experience</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={12} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Fastest Growing Agency</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={150} duration={2} suffix="%" /> : '0%'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Completed Projects</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={320} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                            </dl>
                            <div className="gx uc cys dda">
                                <p className="awj axd ban mt-2" style={{ fontSize: '2.25rem', lineHeight: '2.5rem' }}> Create something <span className="word-switch"></span>.</p>
                                <p className="lk axu" style={{ lineHeight: '2rem', fontSize: '1.125rem' }}>
                                    We are a software based organization that translates your business into a global, oriented strategy.
                                    Schedule an appointment with us to discuss your goals and needs.
                                </p>
                            </div>
                            <a href="/contact">
                                <button
                                    type="submit"
                                    className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center mt-6"
                                >
                                    Connect with us
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Products;