import React, { useState } from "react";
import { FaEye, FaFacebook, FaInstagram, FaPeopleCarry } from 'react-icons/fa';
import { motion } from "framer-motion";
import backgroundImage from '../../assets/images/backgroundAbout.jpg'
import backgroundVideo from '../../assets/images/backgroundAboutVideo.mp4'
import { ImTarget } from "react-icons/im";
import './style.css'

const About = () => {

    const testimonials = [
        {
            quote: "Their team's expertise, professionalism, and commitment to delivering top-notch solutions are truly commendable. Awesome company!!",
            name: "Azaz Malik",
            title: "CEO",
            image: "https://scitforte.com/wp-content/uploads/2024/02/azaz-malik.jpg"
        },
        {
            quote: "From start to finish, their exceptional communication and responsiveness ensured our needs were not just met, but exceeded. Excellent team.",
            name: "M Asif Naseer Khara",
            title: "Chairmain",
            image: "https://scitforte.com/wp-content/uploads/2024/02/yasir.jpeg"
        },
        {
            quote: "Working with Scitforte has been an absolute pleasure. You are so easy to work with and understand our aesthetic and direction so well. I love Scitforte.",
            name: "Yasir Khirmandi",
            title: "Founder",
            image: "https://scitforte.com/wp-content/uploads/2024/02/361087395_814857906880228_3326036032983550166_n-e1709024324739.jpg"
        }
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const { quote, name, title, image } = testimonials[activeIndex];

    const metrics = [
        { title: 'Creators on the platform', value: '8,000+' },
        { title: 'Flat platform fee', value: '3%' },
        { title: 'Uptime guarantee', value: '99.9%' },
        { title: 'Paid out to creators', value: '$70M' },
    ];

    return (
        <div id="about">
            <section
                id="aboutHeading"
                className="relative bg-cover bg-center flex items-center"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                {/* Dark Overlay */}
                <div className="absolute inset-0 bg-[#22194e] opacity-70"></div>

                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full md:w-1/2 p-8 text-white">
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className="mb-6 text-5xl font-bold"
                        >
                            About Us
                        </motion.h2>
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.2 }}
                            className="text-lg mb-4"
                        >
                            We partner with firms to bring clarity to their brand.
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.4 }}
                            className="text-lg mb-4"
                        >
                            We have led innovation and new product delivery in business startup services delivered online.
                        </motion.p>
                    </div>
                </div>
            </section>

            <section id="mission" className="h-screen bg-black flex items-center justify-center">
                <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between w-full">

                    {/* Left Side - Text Content */}
                    <div className="w-full lg:w-1/2 text-white text-left p-8">
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, delay: 0.3 }}
                        >
                            <h3 className="text-2xl font-semibold mb-2 gradient-headings flex"><ImTarget size={30} className="mr-3 text-[#7d97e6]" aria-hidden="true" />Our Mission</h3>
                            <p className="text-lg mb-6">
                                To deliver exceptional software solutions that seamlessly align with our clients' visions, fostering growth and a competitive edge in the digital landscape.
                            </p>
                            <h3 className="text-2xl font-semibold mb-2 gradient-headings flex"><FaEye size={30} className="mr-3 text-[#7d97e6]" aria-hidden="true" />Our Vision</h3>
                            <p className="text-lg mb-6">
                                Empowering innovation with cutting-edge technology solutions that redefine boundaries and possibilities.
                            </p>
                            <h3 className="text-2xl font-semibold mb-2 gradient-headings flex"><FaPeopleCarry size={30} className="mr-3 text-[#7d97e6]" aria-hidden="true" />Our Values</h3>
                            <p className="text-lg">
                                One-stop-shop for all your business needs, so you can excel in what you do best.
                            </p>
                        </motion.div>
                    </div>

                    {/* Right Side - Video */}
                    <div className="w-full lg:w-1/2 h-screen p-0 flex items-center justify-center bg-black">
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className="relative w-full h-full rounded-lg overflow-hidden shadow-lg"
                        >
                            <video
                                className="absolute top-1/2 left-1/2 w-full h-full object-cover"
                                style={{ transform: 'translate(-50%, -50%)', maxWidth: '50%' }} // Center video within container
                                src={backgroundVideo}
                                autoPlay
                                loop
                                muted
                            />
                        </motion.div>
                    </div>
                </div>
            </section>

            <section id="achievement">
                <div className="ab ec adn aje ase py-32">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2850&amp;q=80&amp;blend=111827&amp;blend-mode=multiply&amp;sat=-100&amp;exp=15"
                        className="aa ak ed pn tu aqk"
                    />
                    <div className="ab gx uh arq dit">
                        <div
                            aria-hidden="true"
                            className="aa ay bi ed xp bec bxz bya cvy dmi"
                            style={{ left: '2rem', bottom: '-8rem' }}
                        >
                            <div
                                className=""
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                    opacity: '0.2',
                                    width: '79.125rem',
                                    aspectRatio: '1266 / 975',
                                }}
                            ></div>
                        </div>
                        <div className="gx uc cys dda text-left">
                            <p className="awj axd ban mt-2" style={{ fontSize: '2.25rem', lineHeight: '2.5rem' }}>We build digital brands and experiences</p>
                            <p className="lk axu" style={{ lineHeight: '2rem', fontSize: '1.125rem' }}>
                                We design, build and support websites and apps for clients worldwide. <br/>
                                We make your business stand out. Interested? Let's chat.
                            </p>
                        </div>
                        <dl className="mb yn cfw def text-center justify-center rounded-2xl overflow-hidden gap-0.5 mt-16">
                            <div className="lx yy alw p-8">
                                <dt className="awg awm awv axu">Software Products</dt>
                                <dd className="eo avy awm axd ban my-2">100%</dd>
                                <dt className="awg awm awv axu">Offers a comprehensive suite of business solutions.</dt>
                            </div>
                            <div className="lx yy alw p-8">
                                <dt className="awg awm awv axu">Digital Marketing Services</dt>
                                <dd className="eo avy awm axd ban my-2">99%</dd>
                                <dt className="awg awm awv axu">Trust us to drive your digital success.</dt>
                            </div>
                            <div className="lx yy alw p-8">
                                <dt className="awg awm awv axu">Web Development</dt>
                                <dd className="eo avy awm axd ban my-2">100%</dd>
                                <dt className="awg awm awv axu">Web solutions designed to meet the specific needs of a variety of businesses.</dt>
                            </div>
                            <div className="lx yy alw p-8">
                                <dt className="awg awm awv axu">User Experience</dt>
                                <dd className="eo avy awm axd ban my-2">100%</dd>
                                <dt className="awg awm awv axu">We create visually appealing and user-friendly experiences.</dt>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <section id="founders" className="aje flex flex-col text-white p-8">
                <span className="gradient-headings text-4xl font-bold mb-6 text-[#1e266d]">
                    Meet our founders
                </span>
                <span className="text-gray text-2xl mb-6">
                    The mindset behind our succesful journey and innovation.
                </span>

                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
                    {/* Card 1 */}
                    <div className="ajb p-6 rounded-lg shadow-lg" style={{ maxWidth: '20rem' }}>
                        <img
                            alt="Faisal Mehmood"
                            src="https://scitforte.com/wp-content/uploads/2024/02/WhatsApp-Image-2024-02-13-at-4.18.58-PM-scaled-e1707825020642.jpeg"
                            className="h-60 rounded-full mb-4"
                        />
                        <h3 className="text-xl font-semibold text-white">
                            Faisal Mehmood
                        </h3>
                        <p className="text-gray-500">CEO</p>
                        <ul role="list" className="flex mt-4 justify-center items-center">
                            <li>
                                <a href="#" className="flex mx-6 items-center text-blue-500">
                                    <FaFacebook className="h-10 text-gray-500" aria-hidden="true" />
                                </a>
                            </li>
                            <li className="">
                                <a href="#" className="flex mx-6 items-center text-blue-500">
                                    <FaInstagram className="h-10 text-gray-500" aria-hidden="true" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Card 2 */}
                    <div className="ajb p-6 rounded-lg shadow-lg" style={{ maxWidth: '20rem' }}>
                        <img
                            alt="Dua Siddique"
                            src="https://scitforte.com/wp-content/uploads/2024/02/WhatsApp-Image-2024-02-13-at-4.19.16-PM-e1707825297928.jpeg"
                            className="h-60 rounded-full mb-4"
                        />
                        <h3 className="text-xl font-semibold text-white">
                            Dua Siddique
                        </h3>
                        <p className="text-gray-500">COO</p>
                        <ul role="list" className="flex mt-4 justify-center items-center">
                            <li>
                                <a href="#" className="flex mx-6 items-center text-blue-500">
                                    <FaFacebook className="h-10 text-gray-500" aria-hidden="true" />
                                </a>
                            </li>
                            <li className="">
                                <a href="#" className="flex mx-6 items-center text-blue-500">
                                    <FaInstagram className="h-10 text-gray-500" aria-hidden="true" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="testimonials" className="flex flex-col text-white p-8">

                <span className="gradient-headings text-4xl text-left font-semibold mb-6">
                    What our clients say is important to us.
                </span>
                <span className="text-2xl text-left mb-6">
                    We’re obsessive about knowledge transfer.
                </span>

                <div id="controls-carousel" className="relative w-full text-left md:w-1/2 lg:w-full">
                    <div className="relative p-6">
                        <blockquote className="text-lg mb-4 ml-16 text-white">"{quote}"</blockquote>
                        <div className="flex items-center ml-16">
                            <img src={image} alt={name} className="w-16 h-16 rounded-full mr-4" />
                            <div>
                                <p className="font-semibold text-[#1E69B2]">{name}</p>
                                <p className="text-sm text-gray">{title}</p>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev onClick={handleNext}>
                        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full dark:bg-[#203577] group-hover:bg-[#124e9d] dark:group-hover:bg-[#124e9d]  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-[#203577]  group-focus:outline-none">
                            <svg class="w-4 h-4 text-white dark:bg-[#203577] group-hover:bg-[#124e9d] rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                            </svg>
                            <span class="sr-only">Previous</span>
                        </span>
                    </button>
                    <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next onClick={handlePrev}>
                        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full dark:bg-[#203577] group-hover:bg-white/50 dark:group-hover:bg-[#124e9d]  group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-[#203577]  group-focus:outline-none">
                            <svg class="w-4 h-4 text-white dark:bg-[#203577] group-hover:bg-[#124e9d] rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span class="sr-only">Next</span>
                        </span>
                    </button>
                </div>
            </section>
        </div>
    )
}

export default About;