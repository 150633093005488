import React from "react";
import { PiPhoneCallFill } from "react-icons/pi";
import { SlSpeech } from "react-icons/sl";
import { FaFacebookSquare } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { motion } from "framer-motion";
import backgroundVideo from '../../assets/images/backgroundContact.mp4'

const Contact = () => {
    return (
        <div id="contact">
            {/* Heading Section */}
            <section
                id="heading"
                className="relative h-[70vh] flex items-center justify-center text-white"
            >

                <video
                    className="absolute inset-0 w-full h-full object-cover"
                    src={backgroundVideo}
                    autoPlay
                    loop
                    muted
                />

                <div className="absolute inset-0 bg-black opacity-50"></div> {/* Background overlay */}

                <motion.div
                    className="relative z-10 text-center"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <h1 className="text-5xl font-bold mb-4 gradient-headings">Get in Touch with Us</h1>
                    <p className="text-xl max-w-2xl mx-auto">
                        We're here to answer any questions you may have and create an effective solution for your needs.
                    </p>
                </motion.div>
            </section>

            <section id="cards" className="aje flex flex-col text-white p-8">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">

                    {/* Card 1 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <SlSpeech size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Quick Answers
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Find brief answers to your short questions here.
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="mb-5"
                        >
                            <button
                                type="submit"
                                className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center"
                            >
                                Read more
                            </button>
                        </motion.p>
                    </motion.div>

                    {/* Card 2 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <PiPhoneCallFill size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Let's Talk
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Pick up the phone to chat with a member of our team.
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="mb-5 text-white"
                        >
                            <button
                                type="submit"
                                className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center"
                            >
                                Call Us
                            </button>
                        </motion.p>
                    </motion.div>

                    {/* Card 3 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <FaFacebookSquare size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                We are Social
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Get involved in making the dream a reality today.
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="mb-5 text-white"
                        >
                            <button
                                type="submit"
                                className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center"
                            >
                                Join our Community
                            </button>
                        </motion.p>
                    </motion.div>

                    {/* Card 4 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <BiSupport size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Support
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            We're here to help with any questions or queries.
                        </motion.p>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="mb-5 text-white"
                        >
                            <button
                                type="submit"
                                className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center"
                            >
                                Help & Support
                            </button>
                        </motion.p>
                    </motion.div>
                </div>
            </section>

            <section id="contactUS" className="text-left">
                <div className="ab ec aje">
                    <div className="gx mb uh yn ded">
                        <div className="ab arq asv cvo dit flex items-center">
                            <div className="gx ur cys dcw">
                                <div className="aa as cg ed tu adn bbz bdt dbx">
                                    <svg aria-hidden="true" className="aa ak pn tu apq bfe">
                                        <defs>
                                            <pattern
                                                x="100%"
                                                y="-1"
                                                id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                                                width="200"
                                                height="200"
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <path d="M130 200V.5M.5 .5H200" fill="none"></path>
                                            </pattern>
                                        </defs>
                                        <svg x="100%" y="-1" className="ado aow">
                                            <path
                                                d="M-470.5 0h201v201h-201Z"
                                                strokeWidth="0"
                                            ></path>
                                        </svg>
                                        <rect
                                            fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)"
                                            width="100%"
                                            height="100%"
                                            strokeWidth="0"
                                        ></rect>
                                    </svg>
                                    <div
                                        aria-hidden="true"
                                        className="aa bf dz xp bec cwl cwz"
                                    >
                                        <div
                                            className="mi tl amq amw anv bbf"
                                            style={{
                                                clipPath:
                                                    'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <h2 className="avy awj axd ban">We'd love to hear from you!</h2>
                                <p className="lk awf awx axu">
                                    Whether you have a specific project in mind or just want to explore the possibilities,
                                    we’re here to assist you on your journey to innovation and success.
                                </p>
                                <dd>
                                    <div className="my-6">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.284582344695!2d67.0646703143885!3d24.80716408408695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f7c3e6b2f5d%3A0x6766e731c5643f6e!2s91%20C%2C%20Jami%20Commercial%20Street%2011%2C%20Phase%207%20Ext%20Defence%20Housing%20Authority%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2075500%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1693525160000!5m2!1sen!2sus"
                                            width="100%"
                                            height="300"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                            title="SCITFORTE Location"
                                        ></iframe>
                                    </div>
                                </dd>
                            </div>
                        </div>
                        <form action="#" method="POST" className="arq asw avb cld dit djc aje border-l border-slate-500">
                            <div className="gx ur czt dcw">
                                <div className="mb yn aaq abb cfw">
                                    <div>
                                        <label htmlFor="first-name" className="lu awg awm awv ban">
                                            First name
                                        </label>
                                        <div className="lc">
                                            <input
                                                id="first-name"
                                                name="first-name"
                                                type="text"
                                                autoComplete="given-name"
                                                className="lu tu aeb afh alw arm asb ban bbt bbz bcd bds bnh bni bnt cng cni"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="last-name" className="lu awg awm awv ban">
                                            Last name
                                        </label>
                                        <div className="lc">
                                            <input
                                                id="last-name"
                                                name="last-name"
                                                type="text"
                                                autoComplete="family-name"
                                                className="lu tu aeb afh alw arm asb ban bbt bbz bcd bds bnh bni bnt cng cni"
                                            />
                                        </div>
                                    </div>
                                    <div className="bzh">
                                        <label htmlFor="email" className="lu awg awm awv ban">
                                            Email
                                        </label>
                                        <div className="lc">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className="lu tu aeb afh alw arm asb ban bbt bbz bcd bds bnh bni bnt cng cni"
                                            />
                                        </div>
                                    </div>
                                    <div className="bzh">
                                        <label htmlFor="phone" className="lu awg awm awv ban">
                                            Phone
                                        </label>
                                        <div className="lc">
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                autoComplete="tel"
                                                className="lu tu aeb afh alw arm asb ban bbt bbz bcd bds bnh bni bnt cng cni"
                                            />
                                        </div>
                                    </div>
                                    <div className="bzh">
                                        <label htmlFor="message" className="lu awg awm awv ban">
                                            Message
                                        </label>
                                        <div className="lc">
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows="4"
                                                className="lu tu aeb afh alw arm asb ban bbt bbz bcd bds bnh bni bnt cng cni"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="lm lx zk mt-2 flex justify-end">
                                    <button
                                        type="submit"
                                        className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center"
                                    >
                                        Send message
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;