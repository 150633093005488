import React, { useEffect, useState } from "react";
import '../itServices/style.css';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import backgroundVideo from '../../assets/images/backgroundItServices.mp4';

import cloud from '../../assets/images/itServices/cloud.jpg';
import databaseManagementImage from '../../assets/images/itServices/db.jpg';
import data from '../../assets/images/itServices/data.jpg';
import webServiceImage from '../../assets/images/itServices/webService.jpg';

import ets from '../../assets/images/itServices/ets.jpg';
import maintainence from '../../assets/images/itServices/maintainence.jpg';
import vr from '../../assets/images/itServices/vr.jpg';

import { FaCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { Fade } from 'react-awesome-reveal';

const DigitalMarketing = () => {

    const [loop, setLoop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoop((prev) => !prev);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    const [selectedCardSet1, setSelectedCardSet1] = useState(data);

    const cardSet1 = [
        {
            title: 'Search Engine Optimization',
            description: 'Enhances website visibility, On-Page SEO optimizes site elements, Off-Page SEO builds external authority, Keyword Research identifies relevant terms, and SEO Audits evaluate performance.',
            image: data,
        },
        {
            title: 'Influencer Marketing',
            description: 'Involves identifying influencers, developing a campaign strategy, and managing outreach for impactful collaboration.',
            image: databaseManagementImage,
        },
        {
            title: 'Online Public Relations',
            description: 'Manages Online Reputation, handles Crisis Management, and monitors Brand Perception through various online channels.',
            image: cloud,
        },
        {
            title: 'Conversation Rate Optimization',
            description: 'Implements A/B Testing, optimizes User Experience (UX), and focuses on Landing Page Optimization to improve conversion rates',
            image: webServiceImage,
        },
    ];

    const [selectedCardSet2, setSelectedCardSet2] = useState(ets);

    const cardSet2 = [
        {
            title: 'Analytics and Data Analysis',
            description: 'Involves using Google Analytics for data interpretation, reporting, and gaining insights to inform marketing strategies.',
            image: ets,
        },
        {
            title: 'Chatbot Development',
            description: 'Involves Chatbot Strategy, Development, Integration, and ongoing Maintenance and Optimization for enhanced user interactions.',
            image: maintainence,
        },
        {
            title: 'Virtual Events and Webinars',
            description: 'Covers Event Planning, Promotion, Webinar Hosting, and Post-Event Analysis to create engaging online experiences.',
            image: vr,
        },
    ];

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <div id="digitalMarketing">
            <section id="headingDigitalMarketing" className="flex relative">
                {/* Background Video and Overlay */}
                <video
                    className="absolute inset-0 w-full h-full object-cover"
                    src={backgroundVideo}
                    autoPlay
                    loop
                    muted
                    style={{ opacity: '0.5' }}
                />

                {/* Main Content */}
                <div className="relative max-w-7xl mx-auto flex flex-col items-center justify-center h-screen z-10">
                    <h1
                        className={`text-5xl font-bold text-center text-white typing-effect ${loop ? 'loop' : ''}`}
                    >
                        <span className="gradient-headings">
                            Scitforte Digital Marketing
                        </span>
                    </h1>
                    <p className="text-2xl text-white mt-6 mx-auto max-w-4xl text-center">
                        We facilitate organizations to bring their ideas to reality by using descriptive technologies.
                    </p>
                </div>
            </section>

            <section id="dmCards" className="flex flex-col text-white p-8">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">

                    {/* Card 1 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb px-6 py-12 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500 flex flex-col justify-center items-center text-center"
                    >
                        <div className="flex flex-col">
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Search Engine Marketing
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100"
                        >
                            Manages Google Ads and Bing Ads for targeted ad campaigns management, including Remarketing.
                        </motion.p>
                    </motion.div>

                    {/* Card 2 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb px-6 py-12 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col">
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Social Media Marketing
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100"
                        >
                            Develops content strategy, executes social media advertising, and analyzes performance.
                        </motion.p>
                    </motion.div>

                    {/* Card 3 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb px-6 py-12 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col">
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Content Marketing
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100"
                        >
                            Crafts Content Strategy, engages in Blogging, creates Infographics, and utilizes Video Marketing to convey brand messages effectively
                        </motion.p>
                    </motion.div>

                    {/* Card 4 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb px-6 py-12 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col">
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Email Marketing
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100"
                        >
                            Runs email campaigns, automates processes, creates newsletters, and conducts A/B testing for personalized communication.
                        </motion.p>
                    </motion.div>
                </div>
            </section>

            <section id="dmServices1">

                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full p-8 text-white">
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className="mb-6 gradient-headings text-4xl font-bold"
                        >
                            Collect everything in one place
                        </motion.h2>
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.4 }}
                            className="text-lg mb-4"
                        >
                            We build Prototypes and proof of concepts to get findings, create MVP to
                            validate the idea and develop a complete end to end system.
                        </motion.p>
                    </div>
                </div>

                <div className="cys gap-8 mt-4 flex">
                    <div className="flex-col text-left ml-8 w-1/2">
                        {cardSet1.map((card, index) => (
                            <div
                                key={index}
                                className={`card lx mb-3 aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105 ${selectedCardSet1 === card.image ? 'border-active' : ''}`}
                                onClick={() => setSelectedCardSet1(card.image)}
                            >
                                <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                    <h3 className="text-white flex items-center">
                                        <FaCircle
                                            size={15}
                                            className="ayk mr-2"
                                            aria-hidden="true"
                                        />
                                        {card.title}
                                    </h3>
                                    <p className="axu mt-2">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="relative ct-div-block px-6"
                    >
                        <img
                            className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                            src={selectedCardSet1}
                            alt="Selected Service"
                            style={{
                                width: '100%',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    </div>
                </div>
            </section>

            <section
                id="mobileMarketing"
                className="aje"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            className="relative p-6 group"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            {/* First Image */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/digitalMarketing/mobileMarketing.jpg')}
                                style={{ zIndex: 1 }}
                            />

                            {/* Second Image positioned absolutely */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/digitalMarketing/it-service-3.png')}
                                style={{
                                    position: 'absolute',
                                    top: '15%',
                                    right: '2.5%',
                                    width: '25%',
                                    zIndex: 2,
                                }}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="gradient-headings text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Mobile Marketing
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Engages in Mobile App Advertising, SMS Marketing, and Location-Based Marketing to reach users on mobile devices.
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section id="dmServices2" className="mb-3">
                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full p-8 text-white">
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className="mb-6 text-4xl font-bold"
                        >
                            Trust us to drive your digital success.
                        </motion.h2>
                    </div>
                </div>

                <div className="cys gap-8 mt-4 flex">
                    <div className="flex-col text-left ml-8 w-1/2">
                        {cardSet2.map((card, index) => (
                            <div
                                key={index}
                                className={`card lx mb-3 aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105 ${selectedCardSet2 === card.image ? 'border-active' : ''}`}
                                onClick={() => setSelectedCardSet2(card.image)}
                            >
                                <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                    <h3 className="text-white flex items-center">
                                        <FaCircle
                                            size={15}
                                            className="ayk mr-2"
                                            aria-hidden="true"
                                        />
                                        {card.title}
                                    </h3>
                                    <p className="axu mt-2">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        id="div_block-1520-76"
                        className="relative ct-div-block px-6"
                    >
                        <img
                            className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                            src={selectedCardSet2}
                            alt="Selected Service"
                            style={{
                                width: '100%',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    </div>
                </div>
            </section>

            <section
                id="affiliateMarketing"
                className="aje"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            className="relative p-6 group"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            {/* First Image */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/digitalMarketing/mobileMarketing.jpg')}
                                style={{ zIndex: 1 }}
                            />

                            {/* Second Image positioned absolutely */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/digitalMarketing/it-service-3.png')}
                                style={{
                                    position: 'absolute',
                                    top: '15%',
                                    right: '2.5%',
                                    width: '25%',
                                    zIndex: 2,
                                }}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="gradient-headings text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Affiliate Marketing
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Encompasses Program Setup, Affiliate Recruitment, and Performance Tracking to drive sales through partnerships.
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section
                id="localSEO"
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                                marginLeft: '2rem'
                            }}
                        >
                            <h2
                                className="gradient-headings text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Local Seo
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Focuses on Local Search Optimization, Google My Business Management, and Local Citations to boost visibility in specific geographical areas.
                            </p>
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            className="relative p-6 group"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            {/* First Image */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/itServices/cloud.jpg')}
                                style={{ zIndex: 1 }}
                            />

                            {/* Second Image positioned absolutely */}
                            <img
                                className="ct-image transform transition duration-700 ease-out group-hover:scale-105 rounded-lg"
                                src={require('../../assets/images/digitalMarketing/it-service-3.png')}
                                style={{
                                    position: 'absolute',
                                    top: '15%',
                                    right: '2.5%',
                                    width: '25%',
                                    zIndex: 2,
                                }}
                            />
                        </div>
                    </Fade>
                </div>
            </section>

            <section ref={ref}>
                <div className="aje ase py-16">
                    <div className="gx uh arq dit">
                        <div className="gx uc dcy flex flex-col items-center">
                            <dl className="la mb yn zq adn avr cfw def rounded-2xl mt-6 mb-12">
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Team members</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={20} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Years of experience</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={12} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Fastest Growing Agency</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={150} duration={2} suffix="%" /> : '0%'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Completed Projects</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={320} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                            </dl>
                            <div className="gx uc cys dda">
                                <p className="awj axd ban mt-2" style={{ fontSize: '2.25rem', lineHeight: '2.5rem' }}>We help startups launch and succeed faster.</p>
                                <p className="lk axu" style={{ lineHeight: '2rem', fontSize: '1.125rem' }}>
                                    We’re obsessive about knowledge transfer, so whenever
                                    we’ve developed a unique solution for your business.
                                </p>
                            </div>
                            <a href="/contact">
                                <button
                                    type="submit"
                                    className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center mt-6"
                                >
                                    Connect with us
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DigitalMarketing;