import React, { useEffect, useState } from "react";
import backgroundVideo from '../../assets/images/backgroundItServices.mp4';
import './style.css';
import { FaCircle } from "react-icons/fa";
import { motion } from "framer-motion";

import webServiceImage from '../../assets/images/itServices/webService.jpg';
import databaseManagementImage from '../../assets/images/itServices/db.jpg';
import mobileDevelopmentImage from '../../assets/images/itServices/mobileDev.jpg';
import fintechImage from '../../assets/images/itServices/finance.jpg';
import healthTechImage from '../../assets/images/itServices/health.jpg';

import { Fade } from 'react-awesome-reveal';

import ets from '../../assets/images/itServices/ets.jpg';
import maintainence from '../../assets/images/itServices/maintainence.jpg';
import cloud from '../../assets/images/itServices/cloud.jpg';
import vr from '../../assets/images/itServices/vr.jpg';
import data from '../../assets/images/itServices/data.jpg';

import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import ecommerce from '../../assets/images/itServices/services3/ecommerce.jpg';
import da from '../../assets/images/itServices/services3/da.jpg';
import cyber from '../../assets/images/itServices/services3/cyber.jpg';
import digital from '../../assets/images/itServices/services3/digital.jpg';
import it from '../../assets/images/itServices/services3/it.jpg';

import { CgWebsite } from "react-icons/cg";
import { MdHttp } from "react-icons/md";
import { MdConnectedTv } from "react-icons/md";
import { SiStackshare } from "react-icons/si";

const ItServices = () => {

    const [loop, setLoop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoop((prev) => !prev);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    const [selectedCardSet1, setSelectedCardSet1] = useState(webServiceImage);

    const cardSet1 = [
        {
            title: 'Web Services for any Business',
            description: 'Tailored web solutions designed to meet the specific needs of a variety of businesses.',
            image: webServiceImage,
        },
        {
            title: 'Database Management',
            description: 'Efficient handling and optimization of databases to ensure data integrity and performance.',
            image: databaseManagementImage,
        },
        {
            title: 'Mobile Development',
            description: 'Creation of native and cross-platform mobile applications for iOS app development and Android app development.',
            image: mobileDevelopmentImage,
        },
        {
            title: 'Financial Technology (FinTech) Services',
            description: 'Online payment solutions and Digital wallets.',
            image: fintechImage,
        },
        {
            title: 'HealthTech Services',
            description: 'Telemedicine solutions and Health information systems.',
            image: healthTechImage,
        },
    ];

    const [selectedCardSet2, setSelectedCardSet2] = useState(webServiceImage);

    const cardSet2 = [
        {
            title: 'Education Technology Services',
            description: 'E-learning platforms and Learning management systems (LMS)',
            image: ets,
        },
        {
            title: 'Maintenance Support',
            description: 'Ongoing support and maintenance services to ensure software remains up-to-date and functional.',
            image: maintainence,
        },
        {
            title: 'Cloud Services',
            description: 'Integration and deployment of applications on cloud platforms for scalability and efficiency.',
            image: cloud,
        },
        {
            title: 'Virtual Reality and Augmented Reality',
            description: 'VR/AR app development and Simulation and training solutions.',
            image: vr,
        },
        {
            title: 'Data Storage and Backup Services',
            description: 'Cloud storage and Data backup and recovery.',
            image: data,
        },
    ];

    const [selectedCardSet3, setSelectedCardSet3] = useState(webServiceImage);

    const cardSet3 = [
        {
            title: 'E-commerce Solutions',
            description: 'Online store development and Payment gateway integration',
            image: ecommerce,
        },
        {
            title: 'Data Analytics and Business Intelligence',
            description: 'Data analysis services and Business intelligence solutions',
            image: da,
        },
        {
            title: 'Cybersecurity Services',
            description: 'Network security, Penetration testing and Security consulting',
            image: cyber,
        },
        {
            title: 'Digital Transformation Consulting',
            description: 'Strategy consulting, Change management and Implementation of digital technologies.',
            image: digital,
        },
        {
            title: 'Managed IT Services',
            description: 'IT infrastructure management, Helpdesk support and Remote monitoring and maintenance.',
            image: it,
        },
    ];

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <div id="itServices">
            <section id="headingItServices" className="flex relative">
                {/* Background Video and Overlay */}
                <video
                    className="absolute inset-0 w-full h-full object-cover"
                    src={backgroundVideo}
                    autoPlay
                    loop
                    muted
                    style={{ opacity: '0.5' }}
                />

                {/* Main Content */}
                <div className="relative max-w-7xl mx-auto flex flex-col items-center justify-center h-screen z-10">
                    <h1
                        className={`text-5xl font-bold text-center text-white typing-effect ${loop ? 'loop' : ''}`}
                    >
                        <span className="gradient-headings">
                            Scitforte IT Services
                        </span>
                    </h1>
                    <p className="text-2xl text-white mt-6 mx-auto max-w-4xl text-center">
                        We provide customizable solutions for web development, mobile apps, digital marketing, and branding, ensuring your online success.
                    </p>
                </div>
            </section>

            <section id="services1">

                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full p-8 text-white">
                        <motion.h2
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            className="mb-6 gradient-headings text-4xl font-bold"
                        >
                            Design , Development and Implementation Services
                        </motion.h2>
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.4 }}
                            className="text-lg mb-4"
                        >
                            Crafting tailored solutions across different industries for transformative results.
                        </motion.p>
                    </div>
                </div>

                <div className="cys gap-8 mt-4 flex">
                    <div className="flex-col text-left ml-8">
                        {cardSet1.map((card, index) => (
                            <div
                                key={index}
                                className={`card lx mb-3 aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105 ${selectedCardSet1 === card.image ? 'border-active' : ''}`}
                                onClick={() => setSelectedCardSet1(card.image)}
                            >
                                <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                    <h3 className="text-white flex items-center">
                                        <FaCircle
                                            size={15}
                                            className="ayk mr-2"
                                            aria-hidden="true"
                                        />
                                        {card.title}
                                    </h3>
                                    <p className="axu mt-2">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="relative ct-div-block px-6"
                    >
                        <img
                            className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                            src={selectedCardSet1}
                            alt="Selected Service"
                            style={{
                                width: '100%',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    </div>
                </div>
            </section>

            <section
                id="tools"
                className="aje"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px'
                    }}
                >
                    <Fade direction="left">
                        <div
                            className="relative p-6"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img
                                className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                                src={require('../../assets/images/itServices/tools.jpg')}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="text-white text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Collaboration and Communication Tools
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Elevate customer engagement and satisfaction with a powerful system
                                that organizes, automates, and synchronizes sales, marketing, and customer
                                service, fostering stronger client relationships.
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section id="services2">

                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full p-8 text-white">
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.4 }}
                            className="text-2xl mb-4"
                        >
                            Explore Scitforte's comprehensive software suite for streamlined operations and accelerated growth.
                        </motion.p>
                    </div>
                </div>

                <div className="cys gap-8 mt-4 flex">
                    <div className="flex-col text-left ml-8">
                        {cardSet2.map((card, index) => (
                            <div
                                key={index}
                                className={`card lx mb-3 aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105 ${selectedCardSet2 === card.image ? 'border-active' : ''}`}
                                onClick={() => setSelectedCardSet2(card.image)}
                            >
                                <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                    <h3 className="text-white flex items-center">
                                        <FaCircle
                                            size={15}
                                            className="ayk mr-2"
                                            aria-hidden="true"
                                        />
                                        {card.title}
                                    </h3>
                                    <p className="axu mt-2">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        id="div_block-1520-76"
                        className="relative ct-div-block px-6"
                    >
                        <img
                            id="image-1087-20"
                            className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                            src={selectedCardSet2}
                            alt="Selected Service"
                            style={{
                                width: '100%',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    </div>
                </div>
            </section>

            <section
                id="cloudItService"
                className="aje"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px',
                    }}
                >
                    <Fade direction="left" style={{ maxWidth: '50%' }}>
                        <div
                            className="relative p-6"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img
                                className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                                src={require('../../assets/images/itServices/tools.jpg')}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="text-white text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Cloud Computing Services
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                Platform as a Service (PaaS) and Software as a Service (SaaS)
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section id="services3">

                <div className="container mx-auto flex flex-wrap relative z-10 justify-center">
                    {/* Text Section */}
                    <div className="w-full p-8 text-white">
                        <motion.p
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1.4 }}
                            className="text-lg mb-4"
                        >
                            A customized dashboard and reports provide actionable insights to make better and faster decisions.
                            These insights help in improving existing processes and create new strategies.
                        </motion.p>
                    </div>
                </div>

                <div className="cys gap-8 mt-4 flex">
                    <div className="flex-col text-left ml-8">
                        {cardSet3.map((card, index) => (
                            <div
                                key={index}
                                className={`card lx mb-3 aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105 ${selectedCardSet3 === card.image ? 'border-active' : ''}`}
                                onClick={() => setSelectedCardSet3(card.image)}
                            >
                                <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                    <h3 className="text-white flex items-center">
                                        <FaCircle
                                            size={15}
                                            className="ayk mr-2"
                                            aria-hidden="true"
                                        />
                                        {card.title}
                                    </h3>
                                    <p className="axu mt-2">{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        id="div_block-1520-76"
                        className="relative ct-div-block px-6"
                    >
                        <img
                            id="image-1087-20"
                            className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                            src={selectedCardSet3}
                            alt="Selected Service"
                            style={{
                                width: '100%',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                            }}
                        />
                    </div>
                </div>
            </section>

            <section id="itServicesCards" className="aje flex flex-col text-white p-8">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">

                    {/* Card 1 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <CgWebsite size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Domain Registration
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Registration of domain names
                        </motion.p>
                    </motion.div>

                    {/* Card 2 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <MdHttp size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Web Hosting
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Shared hosting, Virtual Private Server (VPS) hosting and Dedicated server hosting
                        </motion.p>
                    </motion.div>

                    {/* Card 3 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <MdConnectedTv size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                Live streaming Services
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Live streaming solutions and Video-on-demand platforms
                        </motion.p>
                    </motion.div>

                    {/* Card 4 */}
                    <motion.div
                        whileHover={{ scale: 1.1, boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.3)" }}
                        transition={{ duration: 0.3 }}
                        className="ajb p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-indigo-500"
                    >
                        <div className="flex flex-col items-center justify-center">
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <SiStackshare size={30} className="text-white mt-5 mb-5" aria-hidden="true" />
                            </motion.div>
                            <motion.h3
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                                className="text-xl font-semibold text-white mb-5"
                            >
                                BlockchainServices
                            </motion.h3>
                        </div>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="text-gray-100 mb-5"
                        >
                            Blockchain development and Cryptocurrency solutions
                        </motion.p>
                    </motion.div>
                </div>
            </section>

            <section
                id="softwareDevelopment"
                className="aje"
                style={{
                    backgroundImage: 'linear-gradient(to right, rgb(11 47 95 / 50%), transparent 50%), linear-gradient(to left, rgb(11 47 95 / 50%), transparent 50%)',
                    backgroundSize: 'auto',
                    backgroundRepeat: 'no - repeat',
                    backgroundAttachment: 'scroll',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '30px',
                    }}
                >
                    <Fade direction="left" style={{ maxWidth: '50%' }}>
                        <div
                            className="relative p-6"
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img
                                className="ct-image transform transition duration-700 ease-out hover:scale-105 rounded-lg"
                                src={require('../../assets/images/itServices/tools.jpg')}
                            />
                        </div>
                    </Fade>

                    <Fade direction="right">
                        <div
                            style={{
                                gap: '30px',
                                paddingBottom: '0',
                            }}
                        >
                            <h2
                                className="text-white text-left font-semibold text-4xl"
                                style={{
                                    WebkitFontSmoothing: 'initial',
                                    MozOsxFontSmoothing: 'unset',
                                    marginBottom: '12px',
                                    lineHeight: '1.2'
                                }}
                            >
                                Software Development
                            </h2>
                            <p
                                className="text-white"
                                style={{
                                    marginBottom: '0',
                                    textAlign: 'left',
                                }}
                            >
                                End-to-end creation of bespoke software solutions that address unique business requirements <br/>
                                1- Custom software development <br/>
                                2- Application programming interface (API) development
                            </p>
                        </div>
                    </Fade>
                </div>
            </section>

            <section id="itServicesFinalHeading" ref={ref}>
                <div className="aje ase py-16">
                    <div className="gx uh arq dit">
                        <div className="gx uc dcy flex flex-col items-center">
                            <dl className="la mb yn zq adn avr cfw def rounded-2xl mt-6 mb-12">
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Team members</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={20} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Years of experience</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={12} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Fastest Growing Agency</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={150} duration={2} suffix="%" /> : '0%'}
                                    </dd>
                                </div>
                                <div className="lx yy alw p-8">
                                    <dt className="awg awm awv axu">Completed Projects</dt>
                                    <dd className="eo avy awm axd ban">
                                        {inView ? <CountUp end={320} duration={2} suffix="+" /> : '0+'}
                                    </dd>
                                </div>
                            </dl>
                            <div className="gx uc cys dda">
                                <p className="awj axd ban mt-2" style={{ fontSize: '2.25rem', lineHeight: '2.5rem' }}>We help startups launch and succeed faster.</p>
                                <p className="lk axu" style={{ lineHeight: '2rem', fontSize: '1.125rem' }}>
                                    We’re obsessive about knowledge transfer, so whenever
                                    we’ve developed a unique solution for your business.
                                </p>
                            </div>
                            <a href="/contact">
                                <button
                                    type="submit"
                                    className="aeb aju arm asc awg awm ban bbt bit bpb bpc bpe bpk text-white text-center mt-6"
                                >
                                    Connect with us
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ItServices;