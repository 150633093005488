import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import styled, { keyframes } from 'styled-components';
import './style.css';
import backgroundVideo from '../../assets/images/backgroundHome.mp4'
import { RiCheckboxCircleFill, RiWindowFill, RiGovernmentFill} from "react-icons/ri";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { GrSystem } from "react-icons/gr";
import { FaHospitalAlt } from "react-icons/fa";
import { BiSolidBuildingHouse } from "react-icons/bi";
import { FaBuildingUser } from "react-icons/fa6";

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const MarqueeInner = styled.div`
  display: inline-block;
  animation: ${scroll} 20s linear infinite;
`;

const MarqueeItem = styled.div`
  display: inline-block;
  margin: 0 20px;
   img {
    margin: 0 2.5rem;
  }
`;

const Home = () => {
    const { scrollYProgress } = useViewportScroll();

    const contentX = useTransform(scrollYProgress, [0.1, 0.3], [-200, 0]);
    const contentOpacity = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);
    const imageX = useTransform(scrollYProgress, [0.1, 0.3], [200, 0]);
    const imageOpacity = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);

    return (
        <div id="home">
            <section id="intro">
                <div className="flex items-center justify-center h-screen relative">
                    {/* Background Overlay */}
                    <video
                        className="absolute inset-0 w-full h-full object-cover"
                        src={backgroundVideo}
                        autoPlay
                        loop
                        muted
                    />

                    {/* Main Content */}
                    <div className="z-10 max-w-7xl mx-auto">
                        <motion.div
                            className="relative z-10 text-center"
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                        >
                            <h1 className="text-5xl font-bold text-center">
                                <span className="gradient-headings">
                                    Scitforte Pioneering
                                    <span className=""> Tomorrow's Solutions</span>
                                </span>
                            </h1>
                            <p className="text-3xl text-white mt-6 max-w-5xl mx-auto text-center">
                                We are on a mission to revolutionize the digital landscape with
                                cutting-edge software solutions that empower businesses and individuals
                                alike.
                            </p>

                            <div className="mt-8 space-x-4 flex justify-center">
                                <button
                                    type="button"
                                    className="bg-[#203577] text-white font-bold text-lg px-8 py-3 custom-button hover:bg-[#124e9d] focus:outline-none"
                                >
                                    Explore
                                </button>

                                <button
                                    type="button"
                                    className="bg-[#124e9d] text-white font-bold text-lg px-8 py-3 custom-button hover:bg-[#203577] focus:outline-none">
                                    Read more
                                </button>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section >

            <section id="promote" className="py-20"
                style={{
                    backgroundImage: 'radial-gradient( circle closest-side,rgba(18,78,157,0.5),rgba(32,53,119,0) 400px)',
                    padding: "20px",
                    borderRadius: "8px",
                }}>
                <div
                    className="flex items-center justify-center relative max-w-7xl mx-auto"
                >
                    {/* Main Content */}
                    <motion.div
                        className="z-10 text-left max-w-2xl"
                        style={{ x: contentX, opacity: contentOpacity }}
                        transition={{ type: "spring", stiffness: 50 }}
                    >
                        <h1 className="gradient-headings text-5xl font-bold text-[#1d75bb]">
                            Promote your Business through our Expertise
                        </h1>
                        <p className="text-2xl text-white mt-6">
                            Our experienced team is ready to collaborate with you, bringing our innovative spirit,
                            technical expertise, and unwavering commitment to excellence to your project.
                        </p>
                    </motion.div>

                    {/* Image Section */}
                    <motion.div
                        className="flex-shrink-0 mr-8"
                        style={{ x: imageX, opacity: imageOpacity }}
                        transition={{ type: "spring", stiffness: 50 }}
                    >
                        <img src={require('../../assets/images/promote.png')} alt="Promote" className="w-full h-96" />
                    </motion.div>
                </div>

                <p className="gradient-headings text-2xl font-bold text-white mb-6 my-6">SCITFORTE LEVERAGES CUTTING-EDGE TECHNOLOGY STACKS TO POWER YOUR SERVICES.</p>

                <MarqueeContainer>
                    <MarqueeInner>
                        <MarqueeItem><img src={require('../../assets/images/technology/mysql.png')} alt="MySQL" /></MarqueeItem>
                        <MarqueeItem><img src={require('../../assets/images/technology/node.png')} alt="NodeJS" /></MarqueeItem>
                        <MarqueeItem><img src={require('../../assets/images/technology/react.png')} alt="React" /></MarqueeItem>
                        <MarqueeItem><img src={require('../../assets/images/technology/js.png')} alt="JS" /></MarqueeItem>
                        <MarqueeItem><img src={require('../../assets/images/technology/php.png')} alt="PHP" /></MarqueeItem>
                        <MarqueeItem><img src={require('../../assets/images/technology/laravel.png')} alt="Laravel" /></MarqueeItem>
                    </MarqueeInner>
                </MarqueeContainer>

            </section>

            <section id="products">
                <div className="ab ec adn aje ase ckp text-left pb-6">
                    <img
                        alt=""
                        src={require('../../assets/images/backgroundHome3.jpg')}
                        style={{ opacity: '0.1' }}
                        className="aa ak ed pn tu aqk aqo ctr"
                    />
                    <div className="md bxu byb byo byy cbs ccq cfs coa">
                        <div
                            className="mf ti amu amy anw bbf"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        ></div>
                    </div>
                    <div className="aa bx ci ed vm xp bec byv cbh cfg cfs">
                        <div
                            className="mf ti amu amy anw bbf"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        ></div>
                    </div>
                    <div className="gx uh arq dit mt-8 flex justify-between">
                        <div className="gx uc cys">
                            <Fade left>
                                <p
                                    className="awj axd ban mt-2"
                                    style={{ fontSize: '2.25rem', lineHeight: '2.5rem' }}
                                >
                                    We help businesses to grow <br /> and succeed Faster
                                </p>
                                <p className="lk axu" style={{ lineHeight: '2rem', fontSize: '1.125rem' }}>
                                    We offer a comprehensive suite of integrated software <br /> solutions including IMS,
                                    REMS, HMS, ESG, IBS and IMS, <br /> empowering businesses to streamline operations, 
                                    enhance <br /> customer relationships, and optimize inventory management.
                                </p>
                                <ul className="axu text-left mt-6">
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Institute Management System
                                    </li>
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Real Estate Management System
                                    </li>
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Hospital Management System
                                    </li>
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Environmental, Social and Governance
                                    </li>
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Integrated Business Suite
                                    </li>
                                    <li className="flex items-center mb-3">
                                        <RiCheckboxCircleFill className="ayk mx-2" />
                                        Enterprise Resource Planning
                                    </li>
                                </ul>
                            </Fade>
                        </div>
                        <Zoom>
                            <div className="cys dcy det gap-8 mt-4" style={{ display: 'grid' }}>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <GrSystem
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">IBS</h3>
                                        <p className="axu mt-2">Institute Management System</p>
                                    </div>
                                </div>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <BiSolidBuildingHouse
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">REMS</h3>
                                        <p className="axu mt-2">Real Estate Management System</p>
                                    </div>
                                </div>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <FaHospitalAlt
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">HMS</h3>
                                        <p className="axu mt-2">Hospital Management System</p>
                                    </div>
                                </div>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <RiGovernmentFill
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">ESG</h3>
                                        <p className="axu mt-2">Environmental, Social and Governance</p>
                                    </div>
                                </div>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <RiWindowFill
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">IBS</h3>
                                        <p className="axu mt-2">Integrated Business Suite</p>
                                    </div>
                                </div>
                                <div
                                    className="lx aan alw bbz bcd bds p-6 rounded-xl shadow-lg hover:bg-opacity-20 transition duration-300 transform hover:scale-105"
                                >
                                    <div style={{ fontSize: '1rem', lineHeight: '1.75rem' }}>
                                        <FaBuildingUser
                                            size={25}
                                            className="ayk mb-3"
                                            style={{ display: 'block', verticalAlign: 'middle', flex: 'none' }}
                                            aria-hidden="true"
                                        />
                                        <h3 className="awm ban">ERP</h3>
                                        <p className="axu mt-2">Enterprise Resource Planning</p>
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Home;
