import React from 'react';
import logo from '../assets/images/Logo.png';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer
            className="text-white py-8 px-4 text-left"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', boxShadow: '0px 0px 10px rgba(0,0,0,0.3)' }}
        >
            <div className="container mx-auto flex flex-wrap justify-between items-start">

                {/* Social media logos Section */}
                <div className="w-full flex sapce-x-4 justify-end mb-6">
                    <a href="https://www.facebook.com/Scitforte" className="px-2 text-gray-500">
                        <FaFacebook className="h-5 w-5" aria-hidden="true" />
                    </a>
                    <a href="https://www.linkedin.com/company/scitforte/mycompany/" className="px-2 text-gray-500">
                        <FaLinkedin className="h-5 w-5" aria-hidden="true" />
                    </a>
                    <a href="https://www.instagram.com/scitforte?igsh=Zmp3aW5yaDQxaG05" className="px-2 text-gray-500">
                        <FaInstagram className="h-5 w-5" aria-hidden="true" />
                    </a>
                </div>

                {/* Logo and Tagline */}
                <div className="w-full md:w-auto mb-6 md:mb-0">
                    <img src={logo} style={{ filter: 'invert(25%)' }} alt="Scitforte Logo" className="h-10 mb-4" />
                    <p>At Scitforte, we’re passionate about crafting <br /> purposeful software that drives meaningful impact. <br /> Join us in redefining what’s possible in the <br /> digital realm.</p>
                </div>

                {/* About Section */}
                <div className="w-full md:w-auto mb-6 md:mb-0">
                    <h4 className="font-heading font-semibold text-lg mb-4">About</h4>
                    <ul>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/about">About Scitforte</a></li>
                    </ul>
                </div>

                {/* Services Section */}
                <div className="w-full md:w-auto mb-6 md:mb-0">
                    <h4 className="font-heading font-semibold text-lg mb-4">Services</h4>
                    <ul>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices">IT Services</a></li>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/digitalMarketing">Digital Marketing</a></li>
                    </ul>
                </div>

                {/* Products Section */}
                <div className="w-full md:w-2/12 mb-6 md:mb-0">
                    <h4 className="font-heading font-semibold text-lg mb-4">Products</h4>
                    <ul>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices#institeBS">IBS</a></li>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices#REMS">REMS</a></li>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices#HMS">HMS</a></li>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices#ESG">ESG</a></li>
                        <li className="mb-4 hover:text-[#1E699F]"><a href="/itServices#ERP">ERP</a></li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div className="w-full md:w-1/5 mb-6 md:mb-0">
                    <h4 className="font-heading font-semibold text-lg mb-4">Address</h4>
                    <p>Office # 101, Building 91-C, 11th Jami Commercial Street D.H.A 7. P Karachi.</p>
                    <p>Email: <a href="mailto:info@scitforte.com" className="underline">info@scitforte.com</a></p>
                    <p>Phone: +92 306 9771771</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
