import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Home from '../pages/Home/home';
import Footer from '../components/Footer';
import Products from '../pages/Products/product';
import Contact from '../pages/Contact/contact';
import About from '../pages/About/about';
import ItServices from '../pages/itServices/itServices';
import DigitalMarketing from '../pages/digitalMarketing/digitalMarketing';

const Application = () => {
    const location = useLocation();
    const path = location.pathname;

    const renderTabContent = () => {
        if (path === '/') {
            return <Home />;
        } else if (path === '/softwareProducts') {
            return <Products />;
        } else if (path === '/contact') {
            return <Contact />;
        } else if (path === '/about') {
            return <About />;
        } else if (path === '/itServices') {
            return <ItServices />;
        } else if (path === '/digitalMarketing') {
            return <DigitalMarketing />;
        }
        else {
            return <Home />;
        }
    }

    return (
        <div>
            <Navbar />
            {renderTabContent()}
            <Footer />
        </div>
    )
}

export default Application;